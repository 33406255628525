<template>
    <div>
        <div class="flex flex-col flex-1 min-h-0 bg-white border-r border-gray-200">
            <div class="flex flex-col pt-3 pb-4 overflow-y-auto grow">
                <div class="flex items-between px-2 shrink-0">
                    <button
                        class="size-10 flex items-center justify-center rounded-full focus:bg-slate-100"
                        @click="$emit('close')"
                    >
                        <XMarkIconSolid class="size-5" />
                    </button>
                </div>
                <nav
                    class="flex-1 px-2 mt-5 space-y-5 bg-white"
                    aria-label="Sidebar"
                >
                    <NuxtLink
                        active-class="active"
                        class="sidebar-nav-item"
                        to="/"
                    >
                        <ChatBubbleLeftRightIconOutline class="icon" />
                        Messages
                    </NuxtLink>

                    <div
                        v-if="$currentBaby"
                        class="space-y-1"
                    >
                        <h2
                            class="uppercase text-xs text-gray-500 mt-5"
                        >
                            Enfant
                        </h2>
                        <NuxtLink
                            active-class="active"
                            class="sidebar-nav-item"
                            to="/baby"
                        >
                            <UserCircleIconOutline class="icon" />
                            Fiche enfant
                        </NuxtLink>
                        <a
                            v-if="$currentBaby?.registration"
                            class="sidebar-nav-item"
                            :href="`https://abcreche.com/portail-parent/enfants/${$currentBaby.uuid}/registration/${$currentBaby.registration.uuid}/avatar`"
                            target="_blank"
                        >
                            <CameraIconOutline class="icon" />
                            Photo

                            <span class="grow flex justify-end">
                                <ArrowTopRightOnSquareIconOutline class="size-5 text-gray-400" />
                            </span>
                        </a>
                        <a
                            v-if="$currentBaby?.registration"
                            class="sidebar-nav-item"
                            :href="`https://abcreche.com/portail-parent/enfants/${$currentBaby.uuid}/registration/${$currentBaby.registration.uuid}/data`"
                            target="_blank"
                        >
                            <CircleStackIconOutline class="icon" />
                            Données

                            <span class="grow flex justify-end">
                                <ArrowTopRightOnSquareIconOutline class="size-5 text-gray-400" />
                            </span>
                        </a>

                        <NuxtLink
                            active-class="active"
                            class="sidebar-nav-item"
                            to="/documents"
                        >
                            <DocumentMagnifyingGlassIconOutline class="icon" />
                            Documents
                        </NuxtLink>

                        <NuxtLink
                            active-class="active"

                            class="sidebar-nav-item"
                            to="/invoices"
                        >
                            <CurrencyEuroIconOutline class="icon" />
                            Factures
                        </NuxtLink>

                        <NuxtLink
                            v-if="currentBaby?.registration?.creche && currentBaby?.registration?.status === 4"

                            active-class="active"
                            class="sidebar-nav-item"
                            to="/calendar"
                        >
                            <CalendarIconOutline class="icon" />

                            Calendrier
                        </NuxtLink>

                        <NuxtLink
                            v-if="currentBaby?.registration?.creche && currentBaby?.registration?.status === 4"

                            active-class="active"
                            class="sidebar-nav-item"
                            to="/demands"
                        >
                            <QuestionMarkCircleIconOutline class="icon" />

                            Demandes
                        </NuxtLink>

                        <NuxtLink
                            active-class="active"

                            class="sidebar-nav-item"
                            to="/activity"
                        >
                            <RssIconOutline class="icon" />
                            Suivi
                        </NuxtLink>
                    </div>

                    <div
                        v-if="$currentBaby?.registration?.creche"
                        class="space-y-1"
                    >
                        <h2
                            class="uppercase text-xs text-gray-500 mt-5"
                        >
                            Milieu d'accueil
                        </h2>
                        <NuxtLink
                            active-class="active"

                            class="sidebar-nav-item"
                            to="/creche/social"
                        >
                            <MegaphoneIconOutline class="icon" />
                            Publications
                        </NuxtLink>
                        <NuxtLink
                            active-class="active"
                            class="sidebar-nav-item"
                            to="/creche/documents"
                        >
                            <DocumentIconOutline class="icon" />
                            Documents
                        </NuxtLink>
                        <NuxtLink
                            active-class="active"
                            class="sidebar-nav-item"
                            to="/creche/daysoff"
                        >
                            <Icon
                                name="streamline:interface-calendar-lock-calendar-date-day-lock-month-padlock-secure-security"
                                class="icon"
                            />
                            Jours de fermeture
                        </NuxtLink>
                        <NuxtLink
                            active-class="active"
                            class="sidebar-nav-item"
                            to="/creche/events"
                        >
                            <Icon
                                name="streamline:interface-calendar-setting-calendar-cog-date-day-gear-load-loading-month-setting-work"
                                class="icon"
                            />
                            Journées pédagogiques
                        </NuxtLink>
                    </div>
                </nav>
            </div>
            <div
                v-if="$currentBaby"
                class="mb-5 text-center"
            >
                <button @click="$modal.open('qrcode')">
                    <QrCodeIconOutline class="size-10 mx-auto" />
                </button>
            </div>

            <div
                v-if="babies.length"
                class="p-2 max-h-[30vh] overflow-auto space-y-2 border-t border-gray-200"
            >
                <button
                    v-for="baby in babies"
                    :key="baby.uuid"
                    class="block w-full p-2 text-left rounded-lg shrink-0 group"
                    :class="{
                        'ring-2 ring-primary-600': $currentBaby?.uuid === baby.uuid
                    }"
                    @click="switchBaby(baby)"
                >
                    <div class="flex items-center">
                        <div>
                            <UiAvatar
                                class="size-9"
                                :path="baby.avatar"
                            />
                        </div>
                        <div class="ml-3">
                            <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                {{ baby.name }}
                            </p>
                            <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                {{ $filters.age(baby.date_of_birth) }}
                            </p>
                            <div class="text-xs text-gray-600 font-light">
                                {{ baby.registration?.creche?.name }} <span v-if="baby.registration?.classroom">({{
                                    baby.registration?.classroom?.name
                                }})</span>
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { useBabiesStore } from '~/stores/babies.js'

export default defineComponent({
    emits: ['close'],

    computed: {
        ...mapState(useBabiesStore, ['babies', 'currentBaby'])
    },

    methods: {
        ...mapActions(useBabiesStore, ['setCurrentBabyUuid']),

        switchBaby (baby) {
            this.setCurrentBabyUuid(baby.uuid)
            setTimeout(() => {
                window.location.reload()
            }, 300)
        }
    }
})
</script>
