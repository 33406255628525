<template>
    <div>
        <div class="flex flex-col h-screen overflow-hidden">
            <div class="flex items-center justify-between py-3 bg-white shadow px-2">
                <button
                    class="size-10 flex items-center justify-center rounded-full focus:bg-slate-100"
                    @click="mobileMenuOpen = !mobileMenuOpen"
                >
                    <Bars3IconMini class="size-5" />
                </button>

                <UserDropdown />
            </div>

            <main class="flex-grow overflow-auto">
                <slot />
            </main>
            <UiDialog />
            <UiToastWrapper />
        </div>

        <transition
            enter-active-class="duration-500 ease-in-out"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-500 ease-in-out"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="mobileMenuOpen"
                class="absolute inset-0 z-30 transition-opacity bg-gray-500 bg-opacity-75"
                aria-hidden="true"
                @click="mobileMenuOpen = false"
            />
        </transition>
        <AppNavigation
            :class="{
                'translate-x-0': mobileMenuOpen,
                '-translate-x-full': !mobileMenuOpen
            }"
            class="fixed inset-y-0 z-30 transition duration-300 ease-in-out transform left-0 flex w-3/4 max-w-full"
            @close="mobileMenuOpen = false"
        />

        <UiModal
            id="qrcode"
            no-background
        >
            <img
                :src="`https://abcreche.com/qrcode/${currentBaby.registration.uuid}.png`"
                alt="qr code"
                class="mx-auto"
            >
        </UiModal>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import { useBabiesStore } from '~/stores/babies.js'

export default {
    data () {
        return {
            mobileMenuOpen: false
        }
    },

    computed: {
        ...mapState(useAuthStore, ['user']),
        ...mapState(useBabiesStore, ['babies', 'currentBaby'])
    },

    watch: {
        $route () {
            this.mobileMenuOpen = false
        }
    }

}
</script>
